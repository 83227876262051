<!--  -->
<template>
  <div class="wechat-bg">
    <div class="wechat-box">
      <ul>
        <li>
          <img class="wechat-icon" src="@/assets/icon/路径 24@2x.png" alt="" />
        </li>
        <li class="wechat-text">为了更好的用户体验，请访问微信小程序</li>
        <li>
          <button class="wechat-button">
            <a :href="href" target="_parent">访问微信小程序</a>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getWxurl, changeWxurl, getApplyAppId } from "@/api/all.js";
export default {
  data() {
    return {
      href: "",
      username: "",
    };
  },
  created() {
    console.log(window.screen);
    changeWxurl().then((res) => {
      this.username = res.data;
    });
    getWxurl().then((res) => {
      this.href = res.data;
    });
  },
  methods: {
    //访问wx
    getApplyAppId() {
      getApplyAppId().then((res) => {
        localStorage.setItem("appId", res.appId);
      });
    },
  },
};
</script>

<style scoped>
.wechat-bg {
  background-color: #a9a9a9;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
}
.wechat-box {
  width: 47.1rem;
  height: 36.3rem;
  background: linear-gradient(180deg, #dafde6 0%, #ffffff 100%);
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  position: absolute;
  top: 50vh;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.wechat-button {
  width: 32.7rem;
  height: 6.6rem;
  background: #04c15f;
  border-radius: 60px 60px 60px 60px;
  opacity: 1;
  color: #fff;
}
.wechat-icon {
  width: 4.8555rem;
  height: 6.6405rem;
}
.wechat-box > ul {
  height: 36.3rem;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-around;
  align-items: center;
}
.wechat-text {
  color: #777c89;
  font-size: 2.25rem;
}
a {
  color: #fff;
  display: block;
  font-size: 2.85rem;
}
</style>
